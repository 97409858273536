import { PageHeadline } from "../../typography.styles";
import { FormattedMessage } from "react-intl";
import { BookingContent, StyledBooking } from "./styles";
import { Header } from "../../Header/Header";
import { PaymentSuccess } from "../../paymentResult/success";
import { PaymentError } from "../../paymentResult/error";
import { LangSwitcher } from "../../languageSwitcher";
import { BookingContext } from "providers/BookingProvider";
import { useContext } from "react";

export const PaymentResult = ({ type }: { type: string }) => {
  const { isDuende } = useContext(BookingContext);
  return (
    <StyledBooking>
      <div>
        <Header
          backgroundImage={
            isDuende ? "/images/IMG_3968.jpeg" : "/images/01.jpg"
          }
        >
          <>
            <PageHeadline>
              <FormattedMessage
                defaultMessage="Book Flamenco show in Barcelona"
                id="Cj9B8x"
              />
            </PageHeadline>
            <LangSwitcher />
          </>
        </Header>
        <BookingContent direction="column" alignItems="flex-start">
          {type === "error" ? <PaymentError /> : <PaymentSuccess />}
        </BookingContent>
      </div>
    </StyledBooking>
  );
};
