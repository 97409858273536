import { bookingApi } from "api/booking";
import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import { STEP_CALENDAR } from "../../../consts";
import { BookingContext } from "../../../providers/BookingProvider";
import { Footer } from "../../footer";
import { Header } from "../../Header/Header";
import { LangSwitcher } from "../../languageSwitcher";
import { Loader } from "../../loader";
import { PageHeadline } from "../../typography.styles";
import {
  BookingContent,
  StyledBooking,
  StyledBookingContainer,
  StyledLogo,
} from "./styles";
import { HeaderContent } from "components/Header/Header.styled";
import TagManager from "react-gtm-module";

export const Booking = ({ navigate }) => {
  const { search } = useLocation();
  const { formatMessage } = useIntl();
  const {
    setAdultTickets,
    setStartDate,
    isLoading,
    setPriceOption,
    setActiveProduct,
    setFormData,
    setDrinks,
    setHotel,
    isDuende,
  } = useContext(BookingContext);
  const [getCartDetails, { data: cartDetails, isLoading: apiLoader }] =
    bookingApi.endpoints.getCartDetails.useLazyQuery();

  useEffect(() => {
    if (cartDetails) {
      TagManager.dataLayer({
        dataLayer: {
          email: cartDetails.email,
        },
      });

      setStartDate(cartDetails.activeProduct.date);
      setAdultTickets(cartDetails.adultTickets);
      setPriceOption(cartDetails.priceOption);
      setActiveProduct(cartDetails.activeProduct);
      setFormData({
        email: cartDetails.email,
        emailConfirm: cartDetails.email,
        name: cartDetails.name,
        phone: cartDetails.phone,
        nationality: cartDetails.nationality,
        comments: cartDetails.comments,
        termsConditions: true,
        wheelchair: false,
        vegan: false,
      });
      if (cartDetails.hotel) {
        setHotel(cartDetails.hotel);
      }

      if (Object.keys(cartDetails.drinks).length > 0) {
        const cartDrinks = [];
        Object.keys(cartDetails.drinks).forEach((drink) => {
          cartDrinks.push({ [drink]: cartDetails.drinks[drink] });
        });
        setDrinks(cartDrinks);
      }
    }
  }, [cartDetails]);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(search));
    if (params.cartId) {
      getCartDetails({ id: params.cartId });
      return;
    }
    if (Object.keys(params).length !== 0) {
      if (
        params.date &&
        params.adults &&
        typeof Number(params.adults) === "number" &&
        typeof Number(params.child) === "number"
      ) {
        setStartDate(params.date);
        setAdultTickets(Number(params.adults));
        navigate({
          pathname: `/${STEP_CALENDAR}`,
          search,
        });
      }
      if (params.source) {
        let source = params.source;
        if (source[0] === "{" && source[source.length - 1] === "}") {
          source = source.slice(1, -1);
        }
        setHotel(source);
      }
    }

    navigate({
      pathname: "/",
      search,
    });
  }, []);

  return (
    <StyledBooking>
      {isDuende && (
        <Helmet>
          <title>
            {`${formatMessage({
              defaultMessage:
                "Book the Best Flamenco Show Tickets in Barcelona",
              id: "OoMKgu",
            })}`}
          </title>
          <meta
            name="description"
            content={formatMessage({
              defaultMessage:
                "Experience the magic of flamenco in an intimate setting in La Rambla, Barcelona. Book your tickets for a night of passion, rhythm, and culture.",
              id: "1mkYA1",
            })}
          ></meta>
        </Helmet>
      )}

      <StyledBookingContainer>
        <Header
          backgroundImage={
            isDuende ? "/images/IMG_3968.jpeg" : "/images/01.jpg"
          }
        >
          <HeaderContent>
            <StyledLogo
              isDuende={isDuende}
              src={`/images/logo-${isDuende ? "duende.png" : "tablao.svg"}`}
              alt="logo"
            />
            <PageHeadline>
              {formatMessage({
                defaultMessage:
                  "Book your tickets to the best flamenco show in Barcelona",
                id: "07uB1s",
              })}
            </PageHeadline>
            <LangSwitcher />
          </HeaderContent>
        </Header>
        <BookingContent direction="column" alignItems="flex-start">
          {(isLoading || apiLoader) && <Loader />}
          <Outlet />
        </BookingContent>
      </StyledBookingContainer>
      <Footer />
    </StyledBooking>
  );
};
