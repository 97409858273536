import { MediumLink } from "components/typography.styles";
import { useContext, useEffect, useState } from "react";
import { usePromoMutation } from "api/booking";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "components/button/button";
import { FlexContent } from "components/FlexContent/FlexContent";
import { BookingContext } from "providers/BookingProvider";
import { FormError, StyledInput } from "components/views/Booking/Form.styles";

export const PromoCode = ({
  setPromoPrice,
}: {
  setPromoPrice: (price: number) => void;
}) => {
  const [promoInput, showPromoInput] = useState(false);
  const [code, setCode] = useState("");
  const [submitPromoCode, { isSuccess, data: apiResponse }] =
    usePromoMutation();
  const { activeProduct, priceOption, adultTickets, setPromoCode } =
    useContext(BookingContext);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isSuccess && apiResponse.success) {
      setPromoPrice(apiResponse.newPrice * adultTickets);
      setPromoCode(code);
    }
  }, [isSuccess, apiResponse]);

  const handlePromoCode = () => {
    submitPromoCode({
      promoCode: code,
      eventId: activeProduct.eventId,
      priceId: priceOption.priceId,
      currentPrice: priceOption.price,
    });
  };

  return (
    <>
      <MediumLink
        onClick={() => showPromoInput(true)}
        style={{ cursor: "pointer" }}
      >
        <FormattedMessage defaultMessage="I have a discount code" id="AQ8lFC" />
      </MediumLink>
      {promoInput && (
        <>
          <FlexContent>
            <StyledInput
              style={{ width: "100%", marginTop: "10px" }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder={formatMessage({
                defaultMessage: "Enter your code",
                id: "2ZZbWY",
              })}
            />

            <Button
              style={{ marginLeft: "10px" }}
              disabled={code.length < 3}
              onClick={handlePromoCode}
              size="small"
            >
              {formatMessage({ defaultMessage: "Apply", id: "EWw/tK" })}
            </Button>
          </FlexContent>
          {apiResponse && !apiResponse.success && (
            <FormError>
              {formatMessage({ defaultMessage: "Invalid code", id: "x2R8CB" })}
            </FormError>
          )}
        </>
      )}
    </>
  );
};
