import dayjs from "dayjs";
import { useMicrosoftUET } from "providers/MicrosoftUET/MicrosoftUETContext";
import { useContext, useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useReserveMutation } from "../../api/booking";
import { BookingContext } from "../../providers/BookingProvider";
import { PaymentsRedsys } from "../Payments/PaymentsRedsys";

export const FinalizeBooking = () => {
  const flagEnabled = false;
  const navigate = useNavigate();
  const {
    gtmObject,
    adultTickets,
    paymentMethod,
    formData,
    priceOption,
    setIsLoading,
    activeProduct,
    isDuende,
    drinks,
    hotel,
    promoCode,
  } = useContext(BookingContext);
  const { locale } = useIntl();
  const submitRef = useRef<HTMLInputElement>(null);
  const [submitReservation, { isSuccess, data: apiResponse }] =
    useReserveMutation();

  const handlePaypaypal = (url: string) => {
    window.location.href = url;
  };
  const { trackEvent } = useMicrosoftUET();

  const sendReservation = () => {
    const data = {
      people: adultTickets,
      eventId: activeProduct.eventId,
      email: formData.email.trimEnd(),
      name: formData.name.trimEnd(),
      phone: formData.phone.trimEnd(),
      nationality: formData.nationality,
      languageCode: locale,
      comments: formData.comments.trimEnd(),
      wheelchair: formData.wheelchair ? "1" : "0",
      vegan: formData.vegan ? "1" : "0",
      paymentMethod,
      priceId: priceOption.priceId,
      location: activeProduct.place,
      clientId: paymentMethod === "REDSYS" ? "43060000" : "43060264",
    };

    if (promoCode) {
      data["promoCode"] = promoCode;
    }
    if (formData.nif) {
      data["nif"] = formData.nif;
    }

    if (hotel) {
      data["hotel"] = hotel;
    } else if (formData.hotelName) {
      data["hotel"] = formData.hotelName;
    }
    if (isDuende && flagEnabled) {
      data["drinks"] = Object.assign({}, ...drinks);
    }

    submitReservation(data);
  };

  useEffect(() => {
    if (paymentMethod) {
      setIsLoading(true);
      sendReservation();
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (apiResponse && isSuccess) {
      if (apiResponse.hasOwnProperty("errorMessage")) {
        localStorage.removeItem("bookingData");
        navigate("/payment-error");
      }

      localStorage.setItem("email", formData.email);
      localStorage.setItem("phone", formData.phone);
      TagManager.dataLayer({
        dataLayer: {
          event: "add_payment_info",
          ecommerce: {
            currency: "EUR",
            value: adultTickets * priceOption.price,
            payment_type:
              apiResponse.paymentInfo.method === "REDSYS"
                ? "Credit Card"
                : "PayPal",
            items: gtmObject,
          },
        },
      });

      localStorage.setItem(
        "gtmInfo",
        JSON.stringify({
          transaction_id: apiResponse.reservationReference,
          currency: "EUR",
          value: adultTickets * priceOption.price,
          ticket_type: `Adults:${adultTickets}`,
          time_show: dayjs(activeProduct.date).format("HH:mm"),
          product: "Duende",
          booking_type: activeProduct.eventType,
          area_selected: priceOption.priceName,
          show_date: dayjs(activeProduct.date).format("YYYY-MM-DD"),
          items: gtmObject,
        }),
      );

      if (apiResponse.paymentInfo.method === "PAYPAL") {
        handlePaypaypal(apiResponse.paymentInfo.url || "");
      }

      if (apiResponse.paymentInfo.method === "REDSYS" && submitRef.current) {
        submitRef.current.click();
      }
    }
  }, [apiResponse, isSuccess]);

  useEffect(() => {
    if (apiResponse && isSuccess) {
      if (apiResponse.hasOwnProperty("errorMessage")) {
        navigate("/payment-error");
      }

      trackEvent({
        eventName: "ADD_PAYMENT_INFO",
        ecommPagetype: "cart",
        revenueValue: adultTickets * priceOption.price,
        items: [
          {
            payment_type:
              apiResponse.paymentInfo.method === "REDSYS"
                ? "Credit Card"
                : "PayPal",
            gtmObject,
          },
        ],
      });
      localStorage.setItem(
        "UET ADD_PAYMENT_INFO",
        JSON.stringify({
          eventName: "ADD_PAYMENT_INFO",
          value: adultTickets * priceOption.price,
          items: [
            {
              payment_type:
                apiResponse.paymentInfo.method === "REDSYS"
                  ? "Credit Card"
                  : "PayPal",
              gtmObject,
            },
          ],
        }),
      );
    }
  }, [apiResponse, isSuccess]);

  return (
    <>
      {isSuccess && apiResponse.paymentInfo && apiResponse.paymentInfo.form && (
        <PaymentsRedsys
          ref={submitRef}
          signature={apiResponse.paymentInfo.form.Ds_Signature}
          signatureVersion={apiResponse.paymentInfo.form.Ds_SignatureVersion}
          merchantParameters={
            apiResponse.paymentInfo.form.Ds_MerchantParameters
          }
        />
      )}
    </>
  );
};
